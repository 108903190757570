export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT = 'LOGOUT';
export const USER = 'USER';
export const ACTIVATE_LOADER = 'ACTIVATE_LOADER';
export const DEACTIVATE_LOADER = 'DEACTIVATE_LOADER';
export const TOKEN = 'TOKEN';
export const USER_ID = 'USER_ID';
export const FULL_NAME = 'FULL_NAME';
export const GET_ALL_CANDIDATES = 'GET_ALL_CANDIDATES';
export const CANDIDATES_PAGE = 'CANDIDATES_PAGE';
export const TOTAL_ROWS = 'TOTAL_ROWS';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const PDF_REF = 'PDF_REF';
export const USER_TYPE = 'USER_TYPE';
export const PASSWORD_RESETED = 'PASSWORD_RESETED';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';
export const FILTERED_CANDIDATES = 'FILTERED_CANDIDATES';
export const SET_IS_AGE_SELECTED = 'SET_IS_AGE_SELECTED';
export const SET_ARE_LOADED = 'SET_ARE_LOADED';
export const SET_CANDIDATES_PAGE = 'SET_CANDIDATES_PAGE';
export const SET_REQUEST_BODY = 'SET_REQUEST_BODY';
export const SET_TOTAL_CANDIDATES = 'SET_TOTAL_CANDIDATES';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const TOGGLE_SELECTED_ALL = 'TOGGLE_SELECTED_ALL';
export const DESELECT_ALL = 'DESELECT_ALL';
export const SET_SHOWN_SELECTED = 'SET_SHOWN_SELECTED';
export const SET_WITH_REQUESTED_DOCS = 'SET_WITH_REQUESTED_DOCS';
export const SET_WITH_PENDING_REQUESTED_DOCS = 'SET_WITH_PENDING_REQUESTED_DOCS';
export const SET_WITH_DETAILED_INFO = 'SET_WITH_DETAILED_INFO';
export const SET_COUNTRIES_FILTERS = 'SET_COUNTRIES_FILTERS';
export const SET_API_REQUEST_ERROR = 'SET_API_REQUEST_ERROR';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_STATISTICS_ALL = 'SET_STATISTICS_ALL';
export const SET_STATISTICS_EXPERIENCE = 'SET_STATISTICS_EXPERIENCE';
export const SET_STATISTICS_SPECIALIZATION = 'SET_STATISTICS_SPECIALIZATION';
export const SET_PERIOD = 'SET_PERIOD';
export const SET_CALENDAR_ACTIVE = 'SET_CALENDAR_ACTIVE';
export const SET_POPUP_WITH_INFO = 'SET_POPUP_WITH_INFO';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_ACTIVE_PERIODS = 'SET_ACTIVE_PERIODS';
export const MAX_CALENDARS_ERROR = 'MAX_CALENDARS_ERROR';
export const SET_PERIOD_VALID = 'SET_PERIOD_VALID';
export const SET_PERIOD_TO_SHOW = 'SET_PERIOD_TO_SHOW';
export const SET_PERIODS_LOADED = 'SET_PERIODS_LOADED';
export const SET_TABLE_POSITION = 'SET_TABLE_POSITION';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_DAYS = 'SET_DAYS';
export const START_LOADING = 'START_LOADING';
export const FINISH_LOADING = 'FINISH_LOADING';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_DURATION_VALID = 'SET_DURATION_VALID';
export const SET_DOCUMENTS_CATEGORIES = 'SET_DOCUMENTS_CATEGORIES';
export const SET_CANDIDATE_TRANSLATED_CV = 'SET_CANDIDATE_TRANSLATED_CV';
export const SET_WITH_REQUEST_DOCS_CANDIDATES = 'SET_WITH_REQUEST_DOCS_CANDIDATES';
export const SET_WITH_REQUEST_DOCS_PENDING_CANDIDATES = 'SET_WITH_REQUEST_DOCS_PENDING_CANDIDATES';
export const SET_CV_TRANSLATED = 'SET_CV_TRANSLATED';

export const SET_PENDING_CANDIDATES = 'SET_PENDING_CANDIDATES';
export const SET_TOTAL_PENDING_CANDIDATES = 'SET_TOTAL_PENDING_CANDIDATES';
export const SET_ARE_PENDING_LOADED = 'SET_ARE_PENDING_LOADED';
export const SET_PENDING_REQUEST_BODY = 'SET_PENDING_REQUEST_BODY';
export const SET_SELECTED_PENDING_CANDIDATE = 'SET_SELECTED_PENDING_CANDIDATE';
export const SET_PENDING_CANDIDATES_PAGE = 'SET_PENDING_CANDIDATES_PAGE';
export const TOGGLE_ALL_PENDING_SELECTED = 'TOGGLE_ALL_PENDING_SELECTED';
export const DESELECT_ALL_PENDING = 'DESELECT_ALL_PENDING';

export const SET_ONLY_ACTIVE = 'SET_ONLY_ACTIVE';
export const SET_IS_INTERVIEWED_SELECTED = 'SET_IS_INTERVIEWED_SELECTED';
export const SET_IS_HIRED_SELECTED = 'SET_IS_HIRED_SELECTED';
export const SET_IS_REGISTRATION_SELECTED = 'SET_IS_REGISTRATION_SELECTED';

export const SET_EMPLOYERS = 'SET_EMPLOYERS';
export const SET_TOTAL_EMPLOYERS = 'SET_TOTAL_EMPLOYERS';
export const SET_EMPLOYERS_REQUEST_BODY = 'SET_EMPLOYERS_REQUEST_BODY';
export const SET_ARE_EMPLOYERS_LOADED = 'SET_ARE_EMPLOYERS_LOADED';
export const SET_SELECTED_EMPLOYER = 'SET_SELECTED_EMPLOYER';
export const DESELECT_ALL_EMPLOYERS = 'DESELECT_ALL_EMPLOYERS';
export const SET_SELECTED_EMPLOYERS_SHOWN = 'SET_SELECTED_EMPLOYERS_SHOWN';
export const TOGGLE_SELECT_ALL_EMPLOYERS = 'TOGGLE_SELECT_ALL_EMPLOYERS';
export const SET_EMPLOYERS_PAGE = 'SET_EMPLOYERS_PAGE';
export const SET_QUERY = 'SET_QUERY';
export const SET_QUERY_CANDIDATES = 'SET_QUERY_CANDIDATES';

export const SET_AGE_FILTER = 'SET_AGE_FILTER';
export const SET_AGE_RANGE = 'SET_AGE_RANGE';
export const SET_HIRED_FILTER = 'SET_HIRED_FILTER';
export const SET_INTERVIEWED_FILTER = 'SET_INTERVIEWED_FILTER';
export const SET_REGISTRATION_FILTER = 'SET_REGISTRATION_FILTER';
